/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `市場`,
    TRADE: `貿易`,
    SPOT: `現物取引`,
    OPTIONS: `オプション取引`,
    CONTRACT: `契約取引`,
    ASSET: `資産`,
    LOAN: `ローン`,
    USER_CENTER: `マイ`,
    SIGNIN: `ログイン`,
    SIGNUP: `登録`,
    SLOGAN: `CEXIO`
};
