export default {
  INDEX_TOP: require('./index-top.jpg'),
  INDEX_FEATURE_1: require('./index-feature-1.svg'),
  INDEX_FEATURE_2: require('./index-feature-2.svg'),
  INDEX_FEATURE_3: require('./index-feature-3.svg'),
  INDEX_MAP_BG: require('./index-map-bg.png'),
  INDEX_NUMBER_ARROW_UP: require('./index-number-arrow-up.svg'),

  NO_DATA: require('./no-data.png'),

  ARROW: require('./arrow.svg'),

  M_MAIN_BANNER_BG: require('./m-main-banner-bg.svg'),
  M_MAIN_BANNER_COIN: require('./m-main-banner-coin.svg'),
};
