import asset from './asset';
import auth from './auth';
import common from './common';
import market from './market';
import user from './user';
import order from './order';

import { getMainDomainName } from '../../utils/url';

// ----------------------------------
// SERVER
// ----------------------------------
// const SERVER_DEVELOPMENT = 'http://47.108.195.149:31343/api';
const SERVER_DEVELOPMENT = `https://cexous.com`;
export const SERVER_PRODUCTION = `https://${getMainDomainName()}`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export default {
  ...asset(SERVER),
  ...auth(SERVER),
  ...common(SERVER),
  ...market(SERVER),
  ...user(SERVER),
  ...order(SERVER),
};
