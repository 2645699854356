/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `Markets`,
    TRADE: `Trade`,
    SPOT: `Spot`,
    OPTIONS: `Options`,
    CONTRACT: `Contract`,
    ASSET: `Assets`,
    LOAN: `Loan`,
    USER_CENTER: `User Center`,
    SIGNIN: `Log In`,
    SIGNUP: `Sign Up`,
    SLOGAN: `CEXIO`
};
