export default {
  LOGO: require('./logo.png'),
  CUSTOMER: require('./customer.png'),
  
  TRIANGLE_DOWN: require('./triangle-down.png'),

  ICON_LANGUAGE: require('./icon-language.png'),
  ICON_LANGUAGE_HOVER: require('./icon-language-hover.png'),

  CARET_DOWN: require('./caret-down.png'),
  CARET_UP: require('./caret-up.png'),

  APPLE: require('./apple.svg'),
  ANDROID: require('./android.svg'),

  LANGUAGE: require('./language.png'),

  COIN_BTC: require('./coin/coin-btc.svg'),
  COIN_ETH: require('./coin/coin-eth.svg'),
  COIN_XRP: require('./coin/coin-xrp.svg'),
  COIN_LTC: require('./coin/coin-ltc.svg'),
  COIN_MATIC: require('./coin/coin-matic.svg'),
  COIN_SOL: require('./coin/coin-sol.svg'),
  COIN_DOGE: require('./coin/coin-doge.svg'),
  COIN_XLM: require('./coin/coin-xlm.svg'),
  COIN_SUI: require('./coin/coin-sui.svg'),
  COIN_TRX: require('./coin/coin-trx.svg'),
  COIN_ADA: require('./coin/coin-ada.svg'),
  COIN_SHIB: require('./coin/coin-shib.svg'),
  COIN_RUNE: require('./coin/coin-rune.svg'),
  COIN_BCH: require('./coin/coin-bch.svg'),
  COIN_ARB: require('./coin/coin-arb.svg'),
  COIN_LINK: require('./coin/coin-link.svg'),
  COIN_EOS: require('./coin/coin-eos.svg'),
  COIN_ATOM: require('./coin/coin-atom.svg'),
  COIN_DOT: require('./coin/coin-dot.svg'),
  COIN_UNI: require('./coin/coin-uni.svg'),
  COIN_USDT: require('./coin/coin-usdt.svg'),
  COIN_CEXIO: require('./coin/coin-cexio.png'),

  NAV_BACK: require('./nav-back.png'),
  M_LANGUAGE: require('./m-language.png'),
};
