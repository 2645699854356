import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Popover } from 'antd';

// utils
import { isSignIn } from '../../utils/user';
import { setLanguageCode } from '../../utils/language';
import { setTradeType } from '../../utils/localData';

// constants
import {
  LANGUAGE_DATAS,
  TRADE_TYPE_SPOT,
  TRADE_TYPE_OPTIONS,
  TRADE_TYPE_CONTRACT,
} from '../../constants';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentWebHeader';

class WebHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.L = L();
  }

  // private

  // render
  renderLanguage = () => {
    const languageMenu = (
      <Menu>
        {
          LANGUAGE_DATAS.map((data, index) =>
            <Menu.Item key={`${index}`}>
              <div className={`${className}-link`} onClick={() => { setLanguageCode(data.code); window.location.reload() }}>
                {data.name}
              </div>
            </Menu.Item>
          )
        }
      </Menu>
    );

    return (
      <Dropdown overlay={languageMenu}>
        <img
          className={`${className}-language-icon`}
          src={ICONS.ICON_LANGUAGE}
        />
      </Dropdown>
    );
  }

  render() {
    // 当前菜单
    const menu = window.location.pathname.split('/');

    return (
      <div className={`${className}`}>
        <div className={`${className}-box`}>
          <div className={`${className}-left-box`} >
            <img
              className={`${className}-logo`}
              src={ICONS.LOGO}
              onClick={() => window.location.href = '/'}
            />
          </div>

          <div className={`${className}-right-box`}>
            {this.renderLanguage()}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(WebHeader);
