import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { injectReducer } from '../store/reducers';
import rootStore from '../store';


// --------------  首页 --------------
const Index = lazy(() => import(/* webpackChunkName: "Index" */ './Index/Index'));

const Download = lazy(() => import(/* webpackChunkName: "Download" */ './Index/Download'));

// No Access
const NoAccess = lazy(() => import(/* webpackChunkName: "NoAccess" */ './NoAccess'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  Index,
  Download,
  NoAccess,
  PageNotFound,
};

// 查找reducer，component_name 到 path 的映射
const COMPONENT_PATH_MAPPING = {
  'Index': 'Index/Index',
  'Download': 'Index/Download',

  'NoAccess': 'NoAccess',
  'PageNotFound': 'PageNotFound',
}

const AsyncComponent = props => {
  const { componentName } = props;

  import(`./${COMPONENT_PATH_MAPPING[componentName]}/reducer`).then(({ default: reducer }) => {
    injectReducer(rootStore, { key: componentName, reducer });
  });

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
