/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import browserAttr from '../utils/browserAttr';

// components
import WebHeader from '../components/WebHeader';
import WebFooter from '../components/WebFooter';

// utils
import { showCustomerService } from '../utils/customerService';

// asset
import { ICONS } from '../assets';

// languages
import L from './languages';

import './style.scss';

const className = 'pageLayout';

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);

    this.L = L();

    this.state = {
      isMobileMenuShow: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.common.isShowMenu && nextProps.common.isShowMenu) {
      this.setState({isMobileMenuShow: true});
    }
  }

  componentDidMount() {
    console.log((navigator.browserLanguage || navigator.language).toLowerCase().split('-')[0]);
  }

  // private
  
  // render
  renderMobile = () => {
    return (
      <div className={`${className}`}>
        <div className={`${className}-mobile-box`}>
            {this.props.children}
        </div>
        {this.renderCustomer()}
      </div>
    );
  }

  renderWeb = () => {
    return (
      <div className={`${className}`}>
        <div className={`${className}-web-box`}>
          <div className={`${className}-web-content ${className}-web-content${!this.props.common.isShowHeader?'-no-margin':''}`}>
            {this.props.children}
          </div>
        </div>
        {this.renderCustomer()}
      </div>
    );
  }
  
  renderCustomer = () => {
    return null;

    return (
      <div
        className={`${className}-customer`}
        onClick={() => showCustomerService()}
      >
        <img className={`${className}-customer-icon`} src={ICONS.CUSTOMER}/>
      </div>
    );
  }

  render() {
    if (browserAttr.versions.mobile) {
      return this.renderMobile();
    }

    return this.renderWeb();
  }
}

const mapStateToProps = state => ({
  common: state.common,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
