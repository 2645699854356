// -----------------------------
// 常量配置
// -----------------------------
import keyMirror from 'keymirror';

// eslint-disable-next-line
export const LOCAL_STORAGE = keyMirror({
  CONFIG: null,
  ACCESS_TOKEN_CEXIO: null,
  USER_INFO: null,

  SYMBOL: null,
  TRADE_TYPE: null, // 交易类型: spot / options / contract
  LANGUAGE_CODE: null,
});

/**
  * 语言类型
  */

/**
  * 语言类型
  */
export const LANGUAGE_DATAS = [{
    code: 'en',
    name: 'English',
  }, {
    code: 'zh',
    name: '繁體中文',
  }, {
    code: 'ja',
    name: '日本語',
  }, {
    code: 'ko',
    name: '한국인',
  }, {
    code: 'fr',
    name: 'Français',
  }, {
    code: 'es',
    name: 'España',
  }, {
    code: 'pt',
    name: 'Portugal',
  }
];


/**
 * 法币单位
 */
export const MONEY_UNIT = 'USDT';

/**
 * 客服邮箱
 */
export const SERVICE_EMAIL = 'customer@cexous.com';

/**
 * 交易类型 - 现货
 */
export const TRADE_TYPE_SPOT = 'spot';

/**
 * 交易类型 - 期权
 */
export const TRADE_TYPE_OPTIONS = 'options';

/**
 * 交易类型 - 合约
 */
export const TRADE_TYPE_CONTRACT = 'contract';

/**
 * 分页大小
 */
export const PAGE_SIZE = 5;

/**
  * 充提币 支持币种
  */
export const RECHARGE_SUPPORT_COINS = {
  BTC: 'BTC',
  ETH: 'ETH',
  USDTERC20: 'USDT(ERC20)',
  // USDTTRC20: 'USDT(TRC20)',
}